import React from "react"

class Created extends React.Component {
  render() {
    return <p className="text-muted">{this.props.created}</p>
  }
}

export default Created

